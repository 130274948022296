export default function (context) {

    const { store, redirect, from } = context;

    const exFaqURL = 'https://get-africa.ingot.help/hc/en-us';

    if (store.state.currentEntity.iso === 'africa') {
        if (process.server) {
           return redirect(exFaqURL)
        } else {
            window.open(exFaqURL, '_blank')
            return redirect(from.fullPath)
        }
    }

}
